<template>
  <div class="isLoadingTipComponents tc">
    <el-button type="text" :loading="true">
      {{ text ? text : $t("loadingIng") }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="less">
.isLoadingTipComponents {
  .el-button--text {
    color: #666;
    font-size: 16px;
  }
}
</style>