<template>
  <div class="company_video_component">
    <div class="palyVideo">
      <el-image :fit="'cover'" :src="videoInfo.cover_url" class="coverImg"></el-image>
      <div class="mark">
        <i class="el-icon-video-play" @click="playVideo()"></i>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      class="videoCommentDialog"
      :show-close="false"
      width="1180px"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <videoComment
        @close="dialogVisible=false"
        :companyId="companyId"
        :videoCommentOption="videoInfo"
        v-if="dialogVisible"
      ></videoComment>
    </el-dialog>
  </div>
</template>
<script>
import videoComment from "@/baseComponents/videoComment";

export default {
  props: {
    action_id: "",
    videoInfo: {
      default: () => {
        return {
          //video_id:,
          //aliyun_id:'',
          //cover_url:'',
          //name:'
        };
      }
    },
    companyId: {
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      nextCompleteData: null
    };
  },
  computed: {},
  created() {},
  mounted() {

  },
  watch: {},
  methods: {
    playVideo() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.dialogVisible = true;
          }
        });
      } else {

        if (this.action_id == "company.companyDetail.video") {
          this.$log({
            type: 1,
            topic: "video",
            id: "company.companyDetail.video",
            content: JSON.stringify({
              company_id:this.$route.query.company_id,
              video_id:this.videoInfo.video_id
            })
          });
        }
        this.dialogVisible = true;
      }
    }
  },
  components: { videoComment }
};
</script>

<style scoped lang="less">
.company_video_component {
  width: 100%;
  height: 100%;
}
.palyVideo {
  width: 100%;
  height: 100%;
  position: relative;
  .coverImg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .mark {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    color: #aaa;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s;
    .el-icon-video-play {
      font-size: 34px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .mark:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  .mark:hover .el-icon-video-play {
    color: #fff;
  }
  .mark .ppp {
    color: #fff;
  }
}
</style>
