<template>
  <div class="searchCity">
    <p class="title">城市</p>
    <div style="padding: 10px 0">
      <el-input
        v-model="searchCityStr"
        class="input-with-select"
        size="small"
        placeholder="城市"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchCityBtn"
        ></el-button>
      </el-input>
    </div>
    <div class="havaContent">
      <p
        v-for="(item, index) in allCountryCity"
        :key="index"
        @click="cityListClick(item)"
        :title="LOCALE == 'en' ? item.city_name_en : item.city_name_zh"
      >
        {{ LOCALE == "en" ? item.city_name_en : item.city_name_zh }}
      </p>
      <div
        v-if="!allCountryCity.length"
        style="
          text-align: center;
          line-height: 60px;
          font-size: 12px;
          color: #999;
        "
      >
        {{ LOCALE == "en" ? "No Data" : "暂无数据" }}
      </div>
    </div>
  </div>
</template>
<script>
import { getCityListByCountryId } from "~/baseUtils/AddressConfig";

export default {
  props: {
    cityLists: {
      //若城市列表为自定义的城市列表可传,其他情况下传入countryId 即可
      type: Array,
      default() {
        return [];
      },
    },
    countryId: {
      //传国家id拿城市列表. 若cityList存在时 countryId传为0.
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      searchCityStr: "",
      allCountryCity: [],
      countryCity: [],
    };
  },
  mounted() {
    this.getCityList();
    // if (this.countryId) {
    //   this.countryCity = getCityListByCountryId(this.countryId, "zh");
    //   this.allCountryCity = this.countryCity;

    // } else {
    //   // this.countryCity = this.cityLists;
    //   this.getCityList();
    // }
  },
  methods: {
    //上海货代协会-会员公司所在城市列表
    async getCityList() {
      let res = await this.$store.dispatch("API_company/getCompanCityList", {
        source: this.PJSource,
      });
      if (res.success) {
        this.countryCity = res.data;
        this.searchCityBtn()
      } else {
        this.countryCity = [];
      }
    },
    searchCityBtn() {
      let newArr = this.countryCity.filter((res) => {
        return (
          res.city_name_en
            .toLowerCase()
            .indexOf(this.searchCityStr.toLowerCase()) > -1 ||
          res.city_name_zh
            .toLowerCase()
            .indexOf(this.searchCityStr.toLowerCase()) > -1
        );
      });
      this.allCountryCity = newArr;
    },
    clearSearchVal() {
      this.searchCityStr = "";
      if (this.countryId) {
        this.allCountryCity = this.countryCity;
      } else {
        this.countryCity = this.cityLists;
      }
    },
    cityListClick(item) {
      this.$emit("cityChange", item);
    },
  },
};
</script>
<style lang="less" scoped>
.searchCity {
  .title {
    width: 100%;
    line-height: 35px;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
  }
  .havaContent {
    height: 300px;
    overflow: auto;
    p {
      font-size: 12px;
      line-height: 28px;
      padding: 0 10px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      &:hover {
        background: #f2f2f2;
      }
    }
  }
}
</style>