<template>
  <div class="isAllDataTipComponents tc">
    {{ text ? text : $t("isLoadingAll") }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="less">
.isAllDataTipComponents {
  color: #999;
  font-size: 16px;
}
</style>