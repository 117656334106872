<template>
  <div class="vidoDialog clearfix">
    <div class="closeDialog" @click="$emit('close')">
      <i class="el-icon-close"></i>
    </div>

    <div class="dialogFl fl" style="background:#000">
      <div class="videoContent">
        <AliyunVideo :AliyunId="videoCommentOption.aliyun_id"></AliyunVideo>
      </div>
      <div class="clearfix">
        <p
          class="fl"
          style="line-height:40px;color:#f2f2f2;padding-left:10px"
        >{{videoCommentOption.name | textFormat}}</p>
      </div>
    </div>
    <div class="dialogFr fl">
      <div style="padding:0 20px" class="commentWrap">
        <el-input
          type="textarea"
          :placeholder="LOCALE == 'en'?'Please enter your comment':'请输入您的评价'"
          v-model="textarea"
          maxlength="200"
          resize="none"
          show-word-limit
        ></el-input>
        <div class="clearfix">
          <el-button
            class="fr"
            type="primary"
            style="margin:10px 0"
            size="mini"
            v-if="inspectButton('cop:hom:vpc')"
            :disabled="inspectHandle('cop:hom:vpc')"
            @click="gitComment"
            :loading="btnLoading"
          >{{$t('published')}}</el-button>
        </div>
      </div>

      <noData v-if="list.length == 0"></noData>
      <div v-else v-loading="loading" style="padding:0 10px 0 20px">
        <div class="commonlist" style="padding-right:10px">
          <div class="item" v-for="(item,index) in list" :key="index">
            <p class="name clearfix" style="line-height:25px">
              <el-image
                class="fl"
                style="width: 25px; height: 25px;border-radius:50%;margin-right:10px"
                :src="item.comment_user_avatar?item.comment_user_avatar:defaultAvatar"
                :fit="'cover'"
              ></el-image>
              <span
                class="fl"
              >{{LOCALE == 'en'?item.comment_user_name_en:item.comment_user_name_zh}}</span>
            </p>
            <div
              class="company"
            >{{LOCALE == 'en'?item.comment_company_name_en:item.comment_company_name_zh}}</div>
            <div class="content" v-if="!item.showMore" @click="showMore(index)">
              {{LOCALE == 'en'?item.content.substr(0,45): item.content.substr(0,35)}}
              <span
                style="color:#0083F6;cursor:pointer"
                v-if="LOCALE=='en'?item.content.length>45 :item.content.length>35"
              >...{{$t('open')}}</span>
            </div>
            <p class="content" v-else>{{item.content}}</p>
            <p
              class="time"
              style="font-size:12px;text-align:right"
            >{{item.created_time | secondFormat}}</p>
          </div>
          <div class="more" v-if="list.length<total" @click="moreComment">
            {{$t('showMoreComment')}}
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AliyunVideo from "./AliyunVideo";
export default {
  props: {
    videoCommentOption: {
      default: () => {
        return {};
      }
    },
    companyId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      btnLoading: false,
      textarea: "",
      list: [],
      loading: true,
      total: 0
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
    if (this.companyId != this.$store.state.userInfo.company_id) {
      this.$store.dispatch("API_company/company_videoPlayNumber", {
        video_id: this.videoCommentOption.video_id
      });
    }
  },
  watch: {},
  methods: {
    showMore(index) {
      this.list[index].showMore = true;
    },
    moreComment() {
      this.getList(true);
    },
    async getList(more = false) {
      try {
        this.loading = true;
        let params = {
          commented_company_id: this.companyId
            ? this.companyId
            : this.$route.query.company_id,
          related_id: this.videoCommentOption.video_id,
          source: 4
        };
        if (more) {
          params.nopage = 1;
        } else {
          params.start = 0;
          params.limit = 5;
        }
        let data = await this.$store.dispatch(
          "API_company/company_videoComment",
          params
        );
        if (data.success) {
          this.loading = false;

          data.data.forEach(element => {
            element.showMore = false;
          });
          this.total = data.total;
          this.list = data.data;
        }
      } catch {}
    },
    async gitComment() {
      if (this.textarea.trim().length == 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$t("commentEmptyFalse")
        });
        this.textarea = "";
        return;
      }
      try {
        this.btnLoading = true;
        let params = {
          commented_company_id: this.companyId
            ? this.companyId
            : this.$route.query.company_id,
          related_id: this.videoCommentOption.video_id,
          content: this.textarea,
          user_id: this.$store.state.userInfo.id,
          source: 4
        };

        if (this.$store.state.userInfo.company_id) {
          params.company_id = this.$store.state.userInfo.company_id;
        }
        let data = await this.$store.dispatch(
          "API_company/company_gitComment",
          params
        );
        if (data.success) {
          this.btnLoading = false;
          if (this.list.length == this.total) {
            this.getList(true);
          } else {
            this.getList();
          }
          this.textarea = "";
          this.$message({
            showClose: true,
            type: "success",
            message: this.$t("publishedsuccess")
          });
        }
      } catch {
        this.btnLoading = false;
      }
    }
  },
  components: { AliyunVideo }
};
</script>

<style scoped lang="less">
.vidoDialog {
  width: 1180px;
  height: 636px;
  position: relative;

  .closeDialog {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 20px;
    height: 20px;
    background: #f9f9f9;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .dialogFl {
    width: 890px;
  }
  .videoContent {
    width: 100%;
    height: 596px;
  }
  .videoBottom {
    width: 100%;
    height: 40px;
    background: #000;
    padding: 0 15px;
  }
  .dialogFr {
    background: #fff;
    height: 636px;
    width: 290px;
    padding: 20px 0;
    .commentWrap {
      /deep/ .el-input__count {
        right: 18px;
      }
    }

    .more {
      color: #999;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
    .commonlist {
      height: 496px;
      overflow-y: scroll;
      .item {
        padding: 10px 0;
        border-bottom: 1px solid #d6d4d4;

        .name {
          font-size: 14px;
          color: #0083f6;
        }
        .company {
          color: #999;
          font-size: 12px;
          padding: 5px 0;
        }
        .content {
          font-size: 12px;
          padding-bottom: 5px;
          line-height: 18px;
          text-align: justify;
        }
        .time {
          color: #999;
        }
      }
    }
  }
}
</style>
